@import '@styles/mixins.sass'

.container
    margin: 0 20px
    cursor: pointer
    border-bottom: 3px solid transparent
    transition: all .3s ease-in-out
    @include for-small-desktop
        margin: 0 10px
    @include for-tablet
        margin: 0 7px
    a
        min-height: 95px
        display: flex
        align-items: center
        padding: 0 7px
        color: var(--text-color)
        font: var(--font-md20)
        font-weight: 500
        line-height: 20px
        text-decoration: none
        text-align: center
        transition: all .3s ease-in-out
        @include for-small-desktop
            padding: 0 5px
            font: var(--font-md19)
            line-height: 19px
        @include for-tablet
            min-height: 86px
            padding: 0 2px
            font: var(--font-md18)
            line-height: 18px
        @include for-tablet-portrait
            padding: 10px 2px 4px
    &:hover
        a
            color: var(--accent-color-2)

.containerYellow
    @extend .container
    &:hover
        a
            color: var(--accent-color)

.containerActive
    @extend .container
    border-bottom: 3px solid var(--accent-color-2)
    a
        font-weight: 600

.containerActiveYellow
    @extend .containerYellow
    @extend .containerActive
    border-bottom: 3px solid var(--accent-color)

