@import '@styles/mixins.sass'

.container
    a
        display: block
        padding: 12px 0
        text-decoration: none
        font: var(--font-md24)
        color: var(--text-color)
        @include for-tablet
            font: var(--font-md20)
            padding: 10px 0
        @include for-phone
            font: var(--font-md19)
            padding: 8px 0

.yellow
    a
        &:hover
            color: var(--accent-color)

.blue
    a
        &:hover
            color: var(--accent-color-2)

.blueYellow
    a
        &:hover
            -webkit-background-clip: text
            background-clip: text
            color: transparent
            background-image: linear-gradient(to right, var(--accent-color-2) 0%, var(--accent-color-2) 30%, var(--accent-color) 70%, var(--accent-color) 100%)

.yellowActive
    a
        color: var(--accent-color)

.blueActive
    a
        color: var(--accent-color-2)

.blueYellowActive
    a
        -webkit-background-clip: text
        background-clip: text
        color: transparent
        background-image: linear-gradient(to right, var(--accent-color-2) 0%, var(--accent-color-2) 30%, var(--accent-color) 70%, var(--accent-color) 100%)

