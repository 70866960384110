@import '@styles/mixins.sass'

.container
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 9
    pointer-events: none

.containerShown
    @extend .container
    pointer-events: all
    background-color: var(--bg-color-blur)
    backdrop-filter: blur(4px)
    animation: appearing .3s ease-in-out forwards

.containerHidden
    @extend .container
    background-color: rgba(0, 0, 0, .0)
    backdrop-filter: blur(0px)
    animation: disappearing .3s ease-in-out forwards

.contentContainer
    position: absolute
    z-index: 10
    top: 0
    right: 0
    padding: 40px 50px
    min-height: 100vh
    height: 100%
    overflow-y: auto
    width: 600px
    transform: translateX(100%) 
    background-color: var(--bg-color)
    @include for-small-desktop
        width: 500px
        padding: 35px 45px
    @include for-tablet
        width: 410px
        padding: 30px 40px
    @include for-tablet-portrait
        width: 360px
        padding: 25px 30px
    @include for-phone
        width: 85%
        padding: 20px 30px

.contentContainerShown
    @extend .contentContainer
    transform: translateX(0%)
    background-color: var(--bg-color)
    animation: slideIn .3s ease-in-out forwards

.contentContainerHidden
    @extend .contentContainer
    animation: slideOut .3s ease-in-out forwards

.topContainer
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 60px
    @include for-small-desktop
        margin-bottom: 50px
    @include for-tablet
        margin-bottom: 40px
    @include for-tablet-portrait
        margin-bottom: 30px

.logoContainer
    display: flex
    align-items: center
    justify-content: flex-start
    margin-right: 10px
    cursor: pointer
    div
        svg
            height: 60px
            width: 290px
            @include for-tablet
                height: 50px
                width: 250px
            @include for-tablet-portrait
                height: 45px
                width: 220px
            @include for-phone
                height: 36px
                width: 180px

.closeIconContainer
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    cursor: pointer
    height: 40px
    width: 40px
    @include for-tablet
        height: 35px
        width: 35px
    @include for-tablet-portrait
        height: 30px
        width: 30px
    @include for-phone
        height: 25px
        width: 25px
    &:hover
        svg
            transform: rotate(180deg)
    div
        display: flex
        align-items: center
        justify-content: center
        svg
            transition: all 0.3s ease-in-out
            width: 24px
            height: 24px
            @include for-tablet
                width: 20px
                height: 20px

.btnLinkContainer
    display: none
    @include for-phone
        display: block
        padding-bottom: 20px

.subHeaderContainer
    p
        font-size: 30px
        line-height: 32px
        font-weight: 700
        color: var(--accent-color)
        @include for-small-desktop
            font-size: 28px
            line-height: 30px
        @include for-tablet
            font-size: 26px
            line-height: 28px
        @include for-tablet-portrait
            font-size: 24px
            line-height: 26px

.linksContainer
    margin-top: 40px
    margin-bottom: 25px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    @include for-small-desktop
        margin-top: 30px
        margin-bottom: 20px
    @include for-tablet
        margin-top: 20px
        margin-bottom: 10px
    @include for-tablet-portrait
        margin-top: 15px
        margin-bottom: 5px

.langSelectContainer
    margin-bottom: 45px
    @include for-small-desktop
        margin-bottom: 40px
    @include for-tablet
        margin-bottom: 30px
    @include for-tablet-portrait
        margin-bottom: 20px

.hotLineContainer
    padding: 30px 60px
    border: 1px solid var(--text-color)
    @include for-small-desktop
        padding: 30px 45px
    @include for-tablet
        padding: 30px 30px
    @include for-tablet-portrait
        padding: 20px
    h6
        color: var(--accent-color)
        font-weight: 700
        font: var(--font-md24)
        margin-bottom: 5px
        @include for-tablet-portrait
            font: var(--font-md22)
    p
        color: var(--text-color)
        font: var(--font-md18)
        margin-bottom: 30px
        @include for-tablet-portrait
            font: var(--font-md17)
            margin-bottom: 20px

@keyframes appearing
    0%
        backdrop-filter: blur(0px)
        background-color: rgba(0, 0, 0, .0)

    100%
        backdrop-filter: blur(4px)
        background-color: var(--bg-color-blur)

@keyframes disappearing
    0%
        background-color: var(--bg-color-blur)
        backdrop-filter: blur(4px)
    100%
        background-color: rgba(0, 0, 0, .0)
        backdrop-filter: blur(0px)

@keyframes slideIn
    0%
        transform: translateX(100%)
    100%
        transform: translateX(0)

@keyframes slideOut
    0%
        transform: translateX(0)
    100%
        transform: translateX(100%)
