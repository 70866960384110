@import '@styles/mixins.sass'

.container
    font: var(--font-md22)
    font-weight: 600
    color: var(--text-color)
    @include for-small-desktop
        font: var(--font-md24)
        font-weight: 600
    @include for-tablet
        font: var(--font-md22)
        font-weight: 600
    @include for-tablet-portrait
        font: var(--font-md20)
        font-weight: 600

.small
    @extend .container
    font: var(--font-md24)
    font-weight: 500

.blue
    @extend .container
    color: var(--accent-color-2)
    