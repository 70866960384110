@import '@styles/mixins.sass'

.container
    min-height: 97px
    position: fixed
    z-index: 5
    top: 0
    left: 0
    width: 100%
    padding: 0 140px
    display: flex
    align-items: center
    justify-content: space-between
    background-color: var(--primary-dark-color)
    @include for-small-desktop
        padding: 0 80px //23
    @include for-tablet
        padding: 0 60px //19
        min-height: 87px
    @include for-tablet-portrait
        padding: 19px 40px
    @include for-phone
        min-height: 65px
        padding: 15px 15px

.logoContainer
    cursor: pointer
    div
        svg
            width: 175px
            height: 32px
            @include for-small-desktop
                width: 160px
            @include for-tablet
                width: 150px
            @include for-tablet-portrait
                margin-right: 15px
.rightContainer
    display: flex
    align-items: center
    justify-content: center

.btnLinkContainer
    min-width: 250px
    @include for-small-desktop
        min-width: 220px
    @include for-tablet
        min-width: 210px
    @include for-phone
        display: none

.iconBurderContainer
    margin-left: 35px
    padding: 5px
    cursor: pointer
    @include for-small-desktop
        margin-left: 20px
    @include for-tablet
        margin-left: 15px
    &:hover
        div
            svg
                rect
                    fill: var(--accent-color-2)
    div
        display: flex
        align-items: center
        justify-content: center
        width: 36px
        @include for-phone
            width: 30px
        svg
            width: 100%
            rect
                transition: all 0.3s ease-in-out

.iconBurderContainerYellow
    @extend .iconBurderContainer
    &:hover
        div
            svg
                rect
                    fill: var(--accent-color)


.navContainer
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin: 0 30px
    @include for-small-desktop
        margin: 0 20px
    @include for-tablet
        margin: 0 10px
    @include for-tablet-portrait
        display: none