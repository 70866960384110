@import '@styles/mixins.sass'

.container
    background-color: var(--primary-color)
    padding: 55px 140px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @include for-small-desktop
        padding: 55px 100px
    @include for-tablet
        padding: 40px 60px
    @include for-tablet-portrait
        padding: 40px
    @include for-phone
        padding: 30px 15px

.topRow
    margin-bottom: 40px
    @include for-phone
        margin-bottom: 10px

.topRow, .bottomRow
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: space-between
    @include for-tablet-portrait
        flex-wrap: wrap

.bottomRow
    @include for-phone
        flex-direction: column-reverse
        .column
            width: 100%
            margin-bottom: 10px
            p
                margin-top: 15px

.logoContainer
    max-width: 170px
    margin-bottom: 15px
    cursor: pointer
    div
        svg
            width: 100%
            height: 100%

.column
    width: 33.33%
    p
        color: var(--text-color)
    &:nth-child(2)
        margin: 0 40px
    @include for-tablet-portrait
        width: 48%
        &:nth-child(2)
            margin: 0
        &:last-child
            margin-top: 20px
    @include for-phone
        width: 48%
        &:nth-child(2)
            margin: 0 0 0 0

.listContainer
    width: 100%

.smallHeaderContainer
    p
        @include for-small-desktop
            font: var(--font-md20)
        @include for-tablet-portrait
            font: var(--font-md18)

.privacyLinksContainer
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 5px
    a
        font: var(--font-sm)
        color: var(--text-color)
        text-decoration: none
        transition: color 0.3s ease-in-out
        &:hover
            color: var(--accent-color)

@media screen and (max-width: 565px)
    .topRow
        .column
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            &:nth-last-child(2)
                margin-top: 15px

    .privacyLinksContainer
        width: 100%
