@import '@styles/mixins.sass'
@import '@styles/colors.sass'

.newButton
    width: 100%
    font: var(--font-md20)
    font-weight: 400
    padding: 17px 30px
    background-image: linear-gradient(to right, var(--gradient-color), var(--gradient-color-2), var(--gradient-color))
    background-size: 290% 90%
    box-shadow: 0px 0px 20px -2px var(--gradient-color)
    border: 0
    outline: 0
    transition: all .5s ease-in-out
    text-align: center
    color: var(--text-color)
    cursor: pointer
    @include for-small-desktop
        font: var(--font-md19)
        padding: 15px 25px
    @include for-tablet
        font: var(--font-md18)
        padding: 15px 20px
    @include for-tablet-portrait
        font: var(--font-md18)
        padding: 15px
    @include for-phone
        font: var(--font-md17)
        padding: 13px
    &:hover
        background-position: 100% 0
        transition: all .5s ease-in-out

.newButtonNoBlur
    @extend .newButton
    box-shadow: none
    
.newInvertedMedium
    @extend .newInverted
    font: var(--font-md20)
    font-weight: 400
    padding: 12px
    @include for-tablet-portrait
        font: var(--font-md18)
        padding: 11px
    @include for-phone
        font: var(--font-md17)
        padding: 10px

.newInverted
    @extend .newButton
    background-color: transparent
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), var(--gradient-color), var(--gradient-color-2))
    background-size: 290% 90%
    border: 1px solid transparent
    border-image: linear-gradient(145deg, var(--gradient-color), var(--gradient-color-2)) 1
    box-shadow: none
    &:hover
        transition: all .5s ease-in-out

.newInvertedPrimary
    @extend .newInverted
    background-color: var(--primary-color)

.newButtonNoBlurThin
    @extend .newButtonNoBlur
    padding: 8px 12px

.newInvertedThin
    @extend .newInverted
    padding: 8px 12px

.red
    @extend .newInverted
    font-weight: 600
    background-color: $red
    border: 1px solid $red
    color: var(--text-color)
    &:hover
        background: transparent
        border: 1px solid $red
        color: $red

// .disabled
//     @extend .button
//     background-color: gray
//     color: var(--text-color)
//     cursor: not-allowed
//     &:hover
//         background-color: gray
//         color: var(--text-color)

.newDisabled
    @extend .newInverted
    background-image: none
    border: 1px solid gray
    background-color: gray
    border-image: none
    box-shadow: none
    cursor: not-allowed
    &:hover
        border-image: none
        border: 1px solid gray
        background-color: gray
        color: var(--text-color)
        transition: all .5s ease-in-out