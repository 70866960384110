@import '@styles/mixins.sass'

.container
    margin-top: 15px
    @include for-phone
        margin-top: 7px
    a
        width: max-content
        display: flex
        align-items: center
        justify-content: flex-start
        font: var(--font-sm)
        font-weight: 400
        letter-spacing: 0.5px
        text-decoration: none
        color: var(--text-color)
        transition: color 0.3s ease-in-out
        @include for-tablet
            width: 100%
        &:hover
            color: var(--accent-color)

.blueContainer
    a
        &:hover
            color: var(--accent-color-2)

.blueYellowContainer
    a
        &:hover
            color: linear-gradient(90deg, var(--accent-color-2) 0%, var(--accent-color) 100%)

.iconContainer
    margin-right: 8px
    display: flex
    align-items: center
    justify-content: center
    div
        display: flex
        align-items: center
        justify-content: center
        svg
            height: 22px

.blueIconContainer
    svg
        path
            &:first-child
                fill: var(--accent-color-2)

.blueYellowIconContainer
    svg
        path
            &:first-child
                fill: linear-gradient(90deg, var(--accent-color-2) 0%, var(--accent-color) 100%)
